<template lang="pug">
.timeline
  slot
</template>

<script>
  export default {};
</script>

<style lang="sass">
  .timeline
    width: 100%
</style>
