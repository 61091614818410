<template lang="pug">
.brand-wrapper.om-onboarding.enable-app-embed-wrapper
  .om-logo-wrapper
    img.om-logo(:src="require('@/assets/om-logo.svg')")
  .container.enable-app-embed-container
    .row
      .col-12.col-md-4
        .shopify-logo
          img.mb-3.mx-auto(:src="require('@/assets/admin/svg/shopify.svg')")
      .col-12.col-md-8
        om-heading.font-size-2.font-weight-700
          span {{ $t('enableAppEmbed.plsEnable') }}
        .steps-wrapper.d-flex.justify-content-start
          timeline.steps-description
            timeline-item
              template(slot="icon")
                span 1
              template(slot="content")
                i18n.step(path="enableAppEmbed.goToSettings" tag="span")
                  a(href="javascript:void(0);" @click="goToShopify") {{ $t('enableAppEmbed.goToSettings_link') }}
            timeline-item
              template(slot="icon")
                span 2
              template(slot="content")
                span.step {{ $t('enableAppEmbed.enableScript') }}
            timeline-item
              template(slot="icon")
                span 3
              template(slot="content")
                span.step {{ $t('enableAppEmbed.saveAndClose') }}
        om-toast.app-embed-alert(
          v-if="verificationStatus"
          :closable="false"
          :color="toastStatus.color || 'info'"
          :loading="verificationStatus === 'ongoing'"
          type="side"
        )
          span.font-size-0--875
            span {{ $t(`enableAppEmbed.status.${toastStatus.msg || 'info'}`) }}
              om-link.d-inline-block.brand-link-underline(
                primary
                v-if="verificationStatus === 'failed'"
                @click="goToShopify"
              )
                span.ml-2 {{ $t('enableAppEmbed.goToShopify') }}
        .actions-wrapper.d-flex
          om-button(
            primary
            v-if="(verificationStatus === 'failed' && verificationStarted) || verificationStatus === 'noShop'"
            @click="pollAppEmbedInstall(domain.domain)"
          ) {{ $t('enableAppEmbed.tryAgain') }}
          om-button(primary v-if="!verificationStatus" @click="goToShopify") {{ $t('enableAppEmbed.goToShopify') }}
          om-button(
            primary
            v-if="verificationStatus === 'ongoing' || verificationStatus === 'success'"
            :disabled="verificationStatus === 'ongoing'"
            @click="finishOnboarding"
          ) {{ $t('enableAppEmbed.next') }}
          om-button.ml-3(
            secondary
            v-if="!(verificationStatus === 'success')"
            @click="showWarningModal"
          ) {{ $t('enableAppEmbed.enableLater') }}
          .question-icon
            a(
              target="_blank"
              href="https://support.optimonk.com/hc/en-us/articles/8084024297490-How-to-enable-Shopify-App-embeds-"
            )
              component(is="uil-question-circle" size="24")

  app-embed-warning
</template>

<script>
  import AppEmbedWarning from '@/components/Modals/AppEmbedWarning.vue';
  import GET_CAMPAIGN from '@/graphql/GetCampaign.gql';
  import { mapGetters, mapActions } from 'vuex';
  import shopifyAppExtension from '@/mixins/shopifyAppExtension';
  import appEmbed from '@/mixins/appEmbed';
  import onboardingRedirect from '@/mixins/onboardingRedirect';
  import Timeline from '@/components/Timeline';
  import TimelineItem from '@/components/TimelineItem';
  import { UilQuestionCircle } from '@iconscout/vue-unicons';

  export default {
    components: { AppEmbedWarning, Timeline, TimelineItem, UilQuestionCircle },
    mixins: [shopifyAppExtension, appEmbed, onboardingRedirect],
    data() {
      return {
        verificationStarted: false,
        verificationStatus: null,
        domain: null,
        shop: null,
        toastStatusMap: [
          { status: 'success', color: 'success', msg: 'success' },
          { status: 'ongoing', color: 'info', msg: 'info' },
          { status: 'failed', color: 'danger', msg: 'danger' },
          { status: 'noShop', color: 'danger', msg: 'noShop' },
        ],
      };
    },
    computed: {
      ...mapGetters(['domains', 'shopSettings']),
      toastStatus() {
        return this.toastStatusMap.find(({ status }) => {
          return status === this.verificationStatus;
        });
      },
    },
    async mounted() {
      await this.fetchAccount();
      const campaignId = this.$route.params.campaignId;
      this.domain = await this.getCurrentCampaignDomain(campaignId);
      this.shop = this.getShopByDomain(this.domain);
      if (!this.shop) {
        console.error('Shop not found');
        this.verificationStatus = 'noShop';

        return;
      }
      await this.checkAppEmbedInstall(this.shop.myshopify_domain);
    },
    methods: {
      ...mapActions(['fetchAccount']),
      finishOnboarding() {
        this.$store.dispatch('finishOnboarding');
        this.redirectToCampaign();
      },
      getShopByDomain(domain) {
        if (domain) {
          return this.shopSettings.find((shop) => shop.live_domain === domain.domain);
        }
      },
      async getCurrentCampaignDomain(campaignId) {
        const {
          data: { campaign },
        } = await this.$apollo.query({
          query: GET_CAMPAIGN,
          variables: {
            campaignId,
          },
        });

        return this.domains.find((d) => d.domain === campaign.domain);
      },
      showWarningModal() {
        this.$modal.show('app-embed-warning');
      },
      async goToShopify() {
        this.openAppEmbedActivationOnShopify(this.shop.myshopify_domain);
        this.pollAppEmbedInstall(this.domain.domain);
        this.verificationStarted = true;
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .brand-wrapper.enable-app-embed-wrapper
    .actions-wrapper
      .question-icon
        margin-left: 45%
        margin-top: auto
        margin-bottom: auto
    .om-logo
      margin-left: 2rem
    .container
      margin-top: 3.5rem
    .enable-app-embed-container
      max-width: 70%
      .app-embed-alert
        max-width: 1200px
      .app-embed-alert, .actions-wrapper
        margin-top: 40px
    .steps-wrapper
      margin-top: 40px
      .steps-description .step
        width: 100%
        font-size: 1rem
        line-height: 24px
        font-weight: 700
        color: $om-gray-700
</style>
