<template lang="pug">
.timeline-item.d-flex.align-items-start
  .timeline-item-icon-wrapper.d-flex.justify-content-center
    .timeline-item-icon.d-flex.justify-content-center.align-items-center
      slot(name="icon")
  .timeline-item-content.d-flex.align-items-center
    slot(name="content")
</template>
<script>
  export default {};
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .timeline-item
    min-height: 54px
    position: relative

    .timeline-item-icon-wrapper
      margin-right: 1.25rem
      flex-shrink: 0
      align-self: stretch

      .timeline-item-icon
        border-radius: 50%
        background-color: $om-gray-300
        color: $om-gray-700
        width: 32px
        height: 32px
        font-weight: 700
        font-size: 16px
        overflow: hidden
        position: relative
        z-index: 1

      &:after
        content: ''
        position: absolute
        top: 0
        bottom: 0
        left: 15.5px
        height: 100%
        width: 0
        border: 1px dashed #E3E5E8
        overflow: hidden

    .timeline-item-content
      min-height: 32px

    &:last-of-type
      .timeline-item-icon-wrapper:after
        height: 50%
</style>
